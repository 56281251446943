<template>
  <v-app>
    <v-main class="pb-2">
      <v-container fill-height class="pa-0 mt--4">
        <v-row justify="center">
          <v-col cols="12" lg="6">
            <v-card class="mx-auto mt-14" elevation="5" max-width="500">
              <v-card-title class="justify-center mb-3 main-title">
                Perfil
              </v-card-title>
              <v-card-text>
                <v-col>
                  <v-row cols="12" justify="center">
                    <v-icon size="75" color="primary">mdi-account-circle</v-icon>
                  </v-row>
                  <v-row >
                    <v-col cols="12" sm="6" class="pb-0">
                      <v-text-field v-model="forms.detail.email" label="Email" readonly outlined dense hide-details autocomplete="off"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0">
                      <v-text-field v-model="forms.detail.telefono" label="Teléfono" readonly outlined dense autocomplete="off"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row cols="12" class="px-3" >
                    <v-text-field v-model="forms.detail.nombreCompleto" label="Nombre" readonly outlined dense autocomplete="off"></v-text-field>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" class="py-0">
                      <v-text-field v-model="forms.detail.empresa" label="Empresa" readonly outlined dense autocomplete="off"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="py-0">
                      <v-select v-model="forms.detail.sector_profesional" label="Sector profesional" :items="catalogues.sectores_profesionales"
                      item-text="nombre" item-value="id" :rules="rules.sector_profesional" readonly outlined dense autocomplete="off"></v-select>
                    </v-col>
                  </v-row>
                  <v-row cols="12" class="px-3">
                    <v-text-field v-model="forms.detail.rol" label="Rol" readonly outlined dense autocomplete="off"></v-text-field>
                  </v-row>
                  <v-row cols="12" class="px-3">
                    <v-text-field v-model="forms.detail.empresas_asignadas" label="Empresas asignadas" readonly outlined dense autocomplete="off"></v-text-field>
                  </v-row>
                  <v-row cols="12" class="px-3" justify="center">
                    <v-btn color="primary" text class="text-normal" @click="view()">Actualizar</v-btn>
                  </v-row>
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" lg="6">
            <v-card class="mx-auto mt-14" elevation="5" max-width="500">
              <v-card-title class="justify-center mb-3 main-title">
                Equipos de la empresa
              </v-card-title>
              <v-card-text>
                <v-col>
                  <v-select v-model="selected" :items="items" item-text="text" 
                  label="Seleccionar elementos" multiple chips persistent-hint
                  ></v-select>
                  <v-row cols="12" class="px-3" justify="center">
                    <v-btn color="primary" text class="text-normal mt-3" @click="updateItems()">Actualizar</v-btn>
                  </v-row>
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-dialog v-model="dialogs.update" persistent max-width="600px">
          <v-card>
            <v-toolbar dense dark color="primary" elevation="2">
              <v-toolbar-title>Actualizar usuario</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn text small :disabled="loading" @click="cancel()">
                Cerrar
                <v-icon right>mdi-close</v-icon>
              </v-btn>
              <v-btn text small :loading="loading" @click="update()">
                Actualizar
                <v-icon right>mdi-check</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-form ref="formUpdate" lazy-validation>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="forms.update.nombre" label="Nombre" :rules="rules.nombre" :disabled="loading" required></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field v-model="forms.update.apellido_paterno" label="Apellido Paterno" :rules="rules.apellido_paterno" :disabled="loading" required></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field v-model="forms.update.apellido_materno" label="Apellido Materno" :rules="rules.apellido_materno" :disabled="loading" required></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field v-model="forms.update.telefono" label="Teléfono" :rules="rules.telefono" :disabled="loading" required></v-text-field>
                    </v-col>
                    <v-col cols="8">
                      <v-autocomplete v-model="forms.update.sector_profesional" :items="catalogues.sectores_profesionales" item-text="nombre" item-value="id"
                      label="Sector profesional" :rules="rules.sector_profesional" :disabled="loading" required></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-overlay absolute :value="loading" opacity="0.8">
          <div v-if="loading" class="text-center">
            <v-progress-circular indeterminate size="60" color="primary" class="mb-5"></v-progress-circular>
            <p>Cargando...</p>
          </div>
        </v-overlay>
        <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
          {{ msgError }}
        </v-snackbar>
        <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
          {{ msgSuccess }}
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import services from '@/utils/services'

export default {
  name: 'profile',
  data: () => ({
    host: process.env.VUE_APP_API_SERVER,
    name: process.env.VUE_APP_NAME,
    logo: require('@/assets/logo.png'),
    loading: false, 
    error: false,
    msgError: '',
    success: false,
    msgSuccess: '',
    catalogues: {
      sectores_profesionales: []
    },
    forms: {
      detail: {
        email: '',
        telefono: '',
        nombre: '',
        apellido_materno: '',
        apellido_paterno: '',
        nombreCompleto: '',
        empresa: '',
        sector_profesional: '',
        empresas_asignadas: '',
        rol: ''
      },
      update: {
        nombre: '',
        apellido_paterno: '',
        apellido_materno: '',
        telefono: '',
        sector_profesional: ''
      }
    },
    items: [],
    selected: [],
    dialogs: {
      update: false
    },
    rules: {
      nombre: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 100) || 'El campo excede la longitud máxima'
      ],
      apellido_paterno: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 100) || 'El campo excede la longitud máxima'
      ],
      apellido_materno: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 100) || 'El campo excede la longitud máxima'
      ],
      telefono: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 15) || 'El campo excede la longitud máxima'
      ],
      sector_profesional: [
        v => !!v || 'El campo es requerido'
      ]
    }
  }),
  mounted() {
    this.getCatalogues()
    this.refresh()
    this.getItems(this.$store.state.general.company)
  },
  computed: {
    company() {
      return this.$store.state.general.company
    }
  },
  watch: {
    company: function (val) {
      this.getItems(val)
    }
  },
  methods: {
    async getCatalogues() {
      const sector = await this.axios.get(services.routes.professionalSector)
      if (sector.data.data) {
        this.catalogues.sectores_profesionales = sector.data.data
      }
    },
    refresh() {
      this.loading = true
      this.axios.get(services.routes.profile)
        .then(response => {
          let data = JSON.parse(JSON.stringify(response.data.data));
          this.forms.detail.email = data.email
          this.forms.detail.empresa = data.empresa
          this.forms.detail.empresas_asignadas = data.empresas_asignadas.length !== 0 ? data.empresas_asignadas.join(', ') : "No tienes empresas asignadas";
          this.forms.detail.nombreCompleto = data.nombre + " " +  data.apellido_paterno + " " + data.apellido_materno
          this.forms.detail.nombre = data.nombre
          this.forms.detail.apellido_materno = data.apellido_materno
          this.forms.detail.apellido_paterno = data.apellido_paterno
          this.forms.detail.rol = data.rol
          this.forms.detail.sector_profesional = data.sector_profesional
          this.forms.detail.telefono = data.telefono
        })
        .catch(error => {
          this.error = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    view() {
      this.forms.update = {
        nombre: this.forms.detail.nombre,
        apellido_paterno: this.forms.detail.apellido_paterno,
        apellido_materno: this.forms.detail.apellido_materno,
        telefono: this.forms.detail.telefono,
        sector_profesional: this.forms.detail.sector_profesional
      }
      this.dialogs.update = true
    },
    cancel() {
      if (this.dialogs.update) {
        this.$refs.formUpdate.reset()
        for (let key in this.forms.update) {
          this.forms.update[key] = ''
        }
        this.dialogs.update = false
      }
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.forms.update))
        this.axios.put(services.routes.updateProfile, { data })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Perfil actualizado exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    getItems(id) {
      if (id) {
        this.loading = true
        this.items = []
        this.selected = []
        this.axios.get(services.routes.items + '?empresa=' + this.$store.state.general.company)
          .then(response => {
            this.items = response.data.data
            for (let i = 0; i < response.data.data.length; i++){
              if(this.items[i].selected == true){
                this.selected[i] = this.items[i].text 
              }
            }
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    updateItems() {
      this.loading = true
      let data = {
        empresa: this.$store.state.general.company,
        equipos: this.selected
      }
      this.axios.put(services.routes.items, {data} )
        .then(response => {
          this.cancel()
          this.refresh()
          this.success = true
          this.msgSuccess = 'Equipos actualizados exitosamente'
        })
        .catch(error => {
          this.error = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
  }
}
</script>

<style scoped>
  .main-title {
    font-size: 32px;
    color: #5271ff;
  }
  .text-normal {
    text-transform: none !important;
  }
</style>
